import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { createGlobalStyle } from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import useWindowSize from '../hooks/useWindowSize'
import get from 'lodash.get'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import { FormContext } from '../context/form-context'

import Container from '../styles/Container'
import SEO from '../components/SEO'

import cursorWhite from '../images/cursor-white.svg'
import arrowUp from '../images/arrow-up.svg'
import mouseCursorUp from '../images/mouse-cursor-up.png'
import mouseCursorRight from '../images/mouse-cursor-right.png'
import linesBouncing from '../images/lines-bouncing.png'
import dotSmall from '../images/dot-blue-small.svg'
import handImagesSm from '../images/hand-images-sm.png'
import handImagesMd from '../images/hand-images-md.png'
import handImagesLg from '../images/hand-images-lg.png'
import oLetter from '../images/o.svg'
import pencils from '../images/pencils.png'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #002C5E;
    background-image: none;
    // cursor: url(${cursorWhite}) 7 7, auto;
  }
  a, button, label, input, select, textarea {
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
`

const OuterContainer = styled.div`
  overflow: hidden;
`

const Wrapper = styled.div`
  color: #efe0d7;
`

const Heading = styled.h1`
  margin-bottom: 120px;
  margin-top: 86px;
  margin-left: 20px;
  font-family: Dignitas, sans-serif;
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
  max-width: 240px;
  @media (min-width: 680px) {
    max-width: 380px;
    margin-left: 0px;
    margin-top: 96px;
    font-size: 45px;
    line-height: 49px;
    letter-spacing: 1.56px;
  }
  @media (min-width: 980px) {
    max-width: 686px;
    font-size: 52px;
    line-height: 58px;
    letter-spacing: 1.8px;
  }
  @media (min-width: 1200px) {
    max-width: 768px;
    margin-bottom: 180px;
    margin-top: 150px;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: 1.29px;
  }
`

const Elevated = styled.span`
  display: inline;
  position: relative;
  bottom: 0px;
  font-family: MarkOT, sans-serif;
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  @media (min-width: 680px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.35px;
  }
  @media (min-width: 1200px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.45px;
  }
`

const Blue = styled.span`
  color: #0859ff;
  transition: 0.12s ease-in-out;
  :hover {
    color: #f1ab00;
  }
`

const Yellow = styled.span`
  color: #f1ab00;
  @media (min-width: 680px) {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (min-width: 980px) {
    display: inline-block;
  }
`

const Break = styled.br`
  display: none;
  @media (min-width: 980px) {
    display: block;
  }
`

const SecondaryHeadingContainer = styled.div`
  position: relative;
  margin-bottom: 26px;
  @media (min-width: 680px) {
    margin-bottom: 60px;
  }
`

const SecondaryMainHeading = styled.h2`
  max-width: 120px;
  margin-bottom: 97px;
  margin-left: 18px;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.5px;
  @media (min-width: 680px) {
    max-width: 200px;
    font-size: 37px;
    line-height: 40px;
    letter-spacing: 1.28px;
    margin-left: 28px;
    margin-bottom: 119px;
  }
  @media (min-width: 1200px) {
    max-width: 350px;
    margin-bottom: 105px;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: 2.08px;
  }
`

const SecondaryMainHeadingSmall = styled.div`
  max-width: 120px;
  margin-bottom: 97px;
  margin-left: 18px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  @media (min-width: 680px) {
    max-width: 180px;
    margin-bottom: 146px;
    font-size: 22px;
    line-height: 28px;
  }
  @media (min-width: 1200px) {
    max-width: 240px;
    margin-bottom: 195px;
    margin-left: 22px;
    font-size: 30px;
    line-height: 38px;
  }
`

const SecondarySubHeading = styled.h3`
  margin-left: 18px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  @media (min-width: 680px) {
    font-size: 22px;
    line-height: 28px;
  }
  @media (min-width: 1200px) {
    margin-left: 22px;
    font-size: 30px;
    line-height: 38px;
  }
`

const VerticalLine = styled.div`
  width: 3px;
  height: 158px;
  background-color: #efe0d7;
  position: absolute;
  left: 0px;
  top: 5px;
  @media (min-width: 680px) {
    height: 218px;
  }
  @media (min-width: 1200px) {
    width: 4px;
    height: 277px;
    ${p => p.small && 'height: 292px;'}
  }
`

const Pencils = styled.img`
  width: 160px;
  position: absolute;
  top: -33px;
  left: 130px;
  @media (min-width: 400px) {
    left: 160px;
  }
  @media (min-width: 400px) {
    left: 220px;
  }
  @media (min-width: 680px) {
    width: 206px;
    top: 0px;
    left: 310px;
  }
  @media (min-width: 1200px) {
    width: 333px;
    top: -130px;
    left: initial;
    right: 0px;
  }
`

const ParagraphContainer = styled.div`
  @media (min-width: 1200px) {
    display: flex;
  }
`

const MainParagraphContainer = styled.div`
  max-width: 330px;
  margin-left: 36px;
  margin-bottom: 24px;
  @media (min-width: 680px) {
    max-width: 385px;
    margin-bottom: 40px;
  }
  @media (min-width: 1200px) {
    margin-left: 22px;
    margin-bottom: 24px;
    min-width: 485px;
    max-width: 485px;
  }
`

const MainParagraph = styled.p`
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  @media (min-width: 680px) {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.09px;
  }
  @media (min-width: 1200px) {
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.14px;
  }
`

const SubParagraphContainer = styled.div`
  max-width: 330px;
  margin-left: 36px;
  margin-bottom: 75px;
  @media (min-width: 680px) {
    max-width: 350px;
  }
  @media (min-width: 1200px) {
    margin-left: 58px;
    min-width: 273px;
    max-width: 273px;
  }
`

const SubParagraph = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 22px;
  @media (min-width: 680px) {
    margin-bottom: 32px;
    letter-spacing: 0.25px;
  }
`

const SecondaryParagraph = styled.div`
  max-width: 330px;
  margin-left: 40px;
  font-size: 14px;
  line-height: 22px;
  @media (min-width: 680px) {
    max-width: 286px;
    margin-left: 65px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }
  @media (min-width: 1200px) {
    max-width: 273px;
    margin-left: 69px;
  }
  a {
    font-weight: 700;
    color: #0859ff;
  }
`

const DottedBox = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 238px;
  height: 157px;
  margin-top: 75px;
  margin-bottom: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url(${dotSmall});
  @media (min-width: 680px) {
    width: 442px;
    height: 335px;
    background-image: initial;
    margin-bottom: 0px;
  }
  @media (min-width: 1200px) {
    width: 863px;
    height: 569px;
    margin-left: 22px;
    margin-right: 0;
    margin-bottom: 0px;
  }
`

const BoxParagraph = styled.p`
  max-width: 170px;
  margin-top: 26px;
  font-size: 14px;
  line-height: 22px;
  button {
    position: relative;
    font-weight: 700;
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 2px;
      background-color: #efe0d7;
    }
    :hover {
      :after {
        width: 40px;
      }
    }
  }
  @media (min-width: 680px) {
    max-width: 320px;
    font-size: 23px;
    line-height: 36px;
    letter-spacing: 0.44px;
  }
  @media (min-width: 1200px) {
    max-width: 420px;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.5px;
    transform: translate(-22px, 35px);
  }
`

const LinesBouncing = styled.img`
  display: block;
  width: 64px;
  position: relative;
  left: -28px;
  @media (min-width: 680px) {
    width: 210px;
    left: 0px;
  }
  @media (min-width: 1200px) {
    width: 230px;
    left: -2px;
    top: 0px;
  }
`

const HandImagesSm = styled.img`
  position: absolute;
  width: 398px;
  top: 100px;
  top: -83px;
  left: -79px;
  @media (min-width: 680px) {
    display: none;
  }
`
const HandImagesMd = styled.img`
  display: none;
  @media (min-width: 680px) {
    display: block;
    position: absolute;
    width: 586px;
    top: 0px;
    left: 0px;
    z-index: -1;
  }
  @media (min-width: 1200px) {
    display: none;
  }
`

const HandImagesLg = styled.img`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    width: 1058px;
    top: 0px;
    left: 0px;
    z-index: -1;
  }
`

const OContainer = styled.div`
  position: relative;
  width: 238px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 680px) {
    width: 442px;
    margin-bottom: 175px;
  }
  @media (min-width: 1200px) {
    width: 863px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 250px;
  }
`

const OLetter = styled.img`
  width: 81px;
  position: absolute;
  right: 18px;
  top: -160px;
  @media (min-width: 680px) {
    width: 168px;
    height: 164px;
    top: auto;
    right: -20px;
    top: 40px;
  }
  @media (min-width: 1200px) {
    width: 295px;
    height: auto;
    right: 20px;
    top: 150px;
  }
`

const NextPageContainer = styled.div`
  position: relative;
  margin-top: 160px;
  margin-bottom: 110px;
  text-align: center;
  @media (min-width: 1200px) {
    margin-top: 210px;
    margin-bottom: 50px;
  }
`

const NextPage = styled.button`
  display: inline-block;
  position: relative;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.86px;
  color: #0859ff;
  @media (min-width: 1200px) {
    font-size: 110px;
    line-height: 92px;
    letter-spacing: 2.36px;
    padding-top: 29px;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 26px;
    transform: translateX(-25px);
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 5px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 108px;
      }
    }
  }
`

const MouseCursorMobile = styled.img`
  width: 123px;
  position: absolute;
  top: -110px;
  right: 10px;
  @media (min-width: 1200px) {
    display: none;
  }
`

const MouseCursorDesktop = styled.img`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    width: 270px;
    position: absolute;
    top: 190px;
    right: 10px;
  }
`

const ReturnToTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 34px;
  @media (min-width: 1200px) {
    padding-bottom: 154px;
  }
`

const ReturnToTopButton = styled.button`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.11px;
  color: #0859ff;
  position: relative;
  @media (min-width: 1200px) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.13px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 14px;
    transform: translateX(-25px);
    :after {
      transition: 0.1s ease-in-out;
      content: '';
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 1px;
      background-color: #0859ff;
    }
    :hover {
      :after {
        width: 30px;
      }
    }
  }
`

const ReturnToTopImg = styled.img`
  display: block;
  height: 33px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  @media (min-width: 1200px) {
    display: none;
  }
`

const myCustomLink = (type, element, content) => (
  <Link key={element.data.id} to={`${linkResolver(element.data)}/#tutors`}>
    {content}
  </Link>
)

const HowItWorks = () => {
  const { setShowForm } = React.useContext(FormContext)
  const secondary_paragraphs = [
    {"type":"paragraph","text":"We designed our lesson space with long-term courses of study in mind. Each whiteboard becomes a tutor and student’s private study space. After each class, the content of the board will save automatically, and be there for the next class. This allows student and tutor to build a sense of consistency across an extended period of study.","spans":[]},
    {"type":"paragraph","text":"Student and tutor can enter their lesson space at any time outside of the scheduled class time, to either upload materials in advance of an upcoming lesson, or to review existing notes.","spans":[]}
  ];
  const second_paragraph = [
    {"type":"paragraph","text":"Oros partners with education consultants across the world. Each tutor is paired with a consultant who works closely with the student offline, and who closely understands the student’s academic requirements. These consultants fulfil the role of teaching assistant, adding an extra layer of support and security for our tutors.","spans":[{"start":19,"end":40,"type":"hyperlink","data":{"id":"XbB04hYAACsAIt3c","type":"teach_with_us","tags":[],"slug":"teach-with-us","lang":"en-gb","uid":"teach-with-us","link_type":"Document","isBroken":false}}]}
  ];
  const goToTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({ behavior: 'smooth', top: 0 })
    }
  }
  const { width } = useWindowSize()
  React.useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1000)
  }, [])
  return (
    <>
      <GlobalStyle />
      <OuterContainer>
        <SEO title={`How It Works`} description={``} images={``} />
        <Container>
          <Wrapper>
            <Heading>
              If you feel you have what it takes to become an Oros tutor, then here&apos;s how to become part of our
              community in a few simple steps. Get started by{' '}
              <Blue onClick={() => setShowForm(true)}>
                applying online{' '}
                <Parallax disabled={width < 680} styleOuter={{ display: 'inline-block' }} y={['4px', '-28px']}>
                  <Elevated>(click to sign up) </Elevated>
                </Parallax>
              </Blue>{' '}
              <Break />
              Once you&apos;ve submitted your application you will receive an invitation for an{' '}
              <Yellow>
                interview{' '}
                <Parallax disabled={width < 680} styleOuter={{ display: 'inline-block' }} y={['2px', '-28px']}>
                  <Elevated>(say hello) </Elevated>
                </Parallax>
              </Yellow>{' '}
              Finally, create your
              <Break />{' '}
              <Yellow>
                personal profile{' '}
                <Parallax disabled={width < 680} styleOuter={{ display: 'inline-block' }} y={['2px', '-28px']}>
                  <Elevated>(join the team)</Elevated>
                </Parallax>{' '}
              </Yellow>{' '}
              which allows you full flexibility on how to manage when, where and who you teach.
            </Heading>
            <SecondaryHeadingContainer>
              <SecondaryMainHeading>{`what makes us different`}</SecondaryMainHeading>
              <VerticalLine />
              <SecondarySubHeading>{`The Classroom`}</SecondarySubHeading>
              <Pencils src={pencils} alt="" />
            </SecondaryHeadingContainer>
            <ParagraphContainer>
              <MainParagraphContainer>
                <MainParagraph>{`Our online lesson space is built for education. It is an easy-to-use interactive whiteboard with high-quality video and audio. Student and tutor interact in real-time, and can write notes, draw, or upload and annotate teaching materials.`}</MainParagraph>
              </MainParagraphContainer>
              <SubParagraphContainer>
                {secondary_paragraphs &&
                  secondary_paragraphs.map((paragraph, index) => {
                    const text = get(paragraph, 'text')
                    if (text) {
                      return <SubParagraph key={index}>{text}</SubParagraph>
                    }
                  })}
              </SubParagraphContainer>
            </ParagraphContainer>
            <DottedBox>
              <BoxParagraph>
                A highly interactive browser-based classroom designed for online learning.
              </BoxParagraph>
              <Parallax disabled={width < 680} y={width < 1200 ? ['60px', '0px'] : ['235px', '35px']}>
                <LinesBouncing src={linesBouncing} alt="" />
              </Parallax>
              <HandImagesSm src={handImagesSm} alt="" />
              <HandImagesMd src={handImagesMd} alt="" />
              <HandImagesLg src={handImagesLg} alt="" />
            </DottedBox>
            <OContainer>
              {/* <Parallax disabled={width < 680} y={width < 1200 ? ['0px', '-200px'] : ['-150px', '-440px']}> */}
              <Parallax disabled={width < 680} y={width < 1200 ? ['0px', '-200px'] : ['0px', '-290px']}>
                <OLetter src={oLetter} alt="" />
              </Parallax>
            </OContainer>
            <SecondaryHeadingContainer>
              {<SecondaryMainHeadingSmall>{`The Education Consultant`}</SecondaryMainHeadingSmall>}
              <VerticalLine small />
            </SecondaryHeadingContainer>
            <SecondaryParagraph>
              {<RichText render={second_paragraph} serializeHyperlink={myCustomLink} />}
            </SecondaryParagraph>
            <NextPageContainer>
              <NextPage onClick={() => setShowForm(true)}>apply</NextPage>
              <MouseCursorMobile src={mouseCursorUp} alt="" />
              <MouseCursorDesktop src={mouseCursorRight} alt="" />
            </NextPageContainer>
            <ReturnToTopContainer>
              <ReturnToTopButton onClick={goToTop}>
                <ReturnToTopImg src={arrowUp} alt="Return to Top" />
                <div>return to top</div>
              </ReturnToTopButton>
            </ReturnToTopContainer>
          </Wrapper>
        </Container>
      </OuterContainer>
    </>
  )
}

export default HowItWorks